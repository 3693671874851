.pxl-form-listbox {
    .add-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;

        button {
            margin-left: 1em;
        }
    }

    .items {
        border: 1px solid #bbb;
        border-radius: 3px;
        background-color: #f9f9f9;
        padding: 12px;
        display: flex;
        flex-direction: column;

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &:not(:last-of-type) {
                margin-bottom: 1em;
            }
        }

        .item-data {
            display: flex;

            .drag-handle {
                margin-right: 1em;
            }
        }

        .item-actions {
            a {
                color: #707478;
            }
        }
    }
}
