.pxl-form-items {
    .form-group {
        margin: 0;
    }

    .add-button {
        margin-bottom: 1em;
    }

    .items,
    .no-items,
    .load-error {
        margin: 1em 0 0;
    }

    .items {
        border: 1px solid #bbb;
        border-radius: 3px;
        background-color: #f9f9f9;
        padding: 14px 18px;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: 1.5em;
        }

        .item-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .drag-handle {
                margin-right: 1.5em;
            }
        }

        .item-data {
            display: flex;
            flex-direction: column;
            width: 100%;

            .item-data-field {
                display: flex;
                align-items: center;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 0.75em;
                }

                label {
                    display: block;
                    width: 100px;
                    font-weight: bold;
                }

                .form-control {
                    width: 500px;
                }
            }
        }

        .item-asset {
            cursor: pointer;
        }

        .asset-picker-container {
            &.hide {
                display: none;
            }
        }

        .item-actions {
            margin-left: 1.5em;

            .remove-button {
                color: #6f7478;
            }
        }

        img {
            display: block;
            height: 85px;
        }
    }

    .no-items {
        text-align: left;
    }
}
