.pxl-form-gallery {
    .add-button {
        margin-bottom: 1em;
    }

    .remove-button {
        margin-bottom: 1em;
    }

    .assets {
        border: 1px solid #bbb;
        border-radius: 3px;
        background-color: #f9f9f9;
        padding: 14px 18px;
    }

    .asset {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: 0.8em;
        }

        .asset-content {
            display: flex;
            align-items: center;
            justify-content: center;

            .drag-handle {
                margin-right: 1em;
            }

            .asset-image {
                margin-right: 1em;
                width: 175px;
                cursor: zoom-in;
            }

            .asset-info {
                display: flex;
                flex-direction: column;
                height: 85px;
            }
        }

        .asset-text {
            width: 50%;
            align-self: flex-start;
        }

        .asset-actions {
            margin-left: 1em;

            .remove-button {
                color: #6f7478;
            }
        }

        img {
            display: block;
            height: 85px;
        }
    }

    .no-assets {
        margin-top: 1em;
    }
}
