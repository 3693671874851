.dreamworks-resource-form {
    h1 {
        font-size: 1.4em;
        margin: 0 0 0.5em;
    }

    h2 {
        font-size: 1.2em;
        margin: 0 0 0.6em;
    }

    .add-toolbar {
        margin-bottom: 1em;
    }

    .items {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.hidden {
            display: none;
        }

        .items-columns,
        .items-row {
            display: flex;
            width: 100%;
            // justify-content: space-between;
        }

        .items-columns {
            border-bottom: 1px solid #aaa;
        }

        .items-rows {
            display: flex;
            flex-direction: column;
        }

        .item-image {
            img {
                display: block;
                max-height: 80px;
                max-width: 175px;
                cursor: zoom-in;
            }
        }

        .form-option-column,
        .item-column {
            width: 250px;
            padding: 0.7em 0.4em;
        }

        .item-column-manage {
            button {
                &:not(:last-of-type) {
                    margin-right: 0.4em;
                }
            }
        }

        .form-option-column {
            font-weight: bold;
        }

        .items-row {
            display: flex;
            align-items: flex-start;

            &:nth-child(even) {
                background-color: #f6f6f6;
            }
        }
    }

    .no-resources-text {
        margin: 0;
    }

    .edit-resource-container {
        padding: 1.3em 1.2em 1.4em;
        border-radius: 3px;
        border-top: 0;
        border-bottom: 1px solid #DDD;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .3), 0 -1px 1px #FFF, 0 1px 0 #FFF;
        background-color: #f9f9f9;

        .form-control {
            background-color: #fff;
        }
    }
}
