.faqs {
    .service-resource-header {
        & > div:first-child {
            width: 60px;
        }
        
        & > div:last-child {
            width: 120px;
        }
    }   
}