@font-face {
    font-family: 'alright_sansblack';
    src: url('/fonts/alrightsans-black-webfont.eot');
    src: url('/fonts/alrightsans-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-black-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-black-webfont.woff') format('woff'),
    url('/fonts/alrightsans-black-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-black-webfont.svg#alright_sansblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alright_sansblack_italic';
    src: url('/fonts/alrightsans-blackitalic-webfont.eot');
    src: url('/fonts/alrightsans-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-blackitalic-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-blackitalic-webfont.woff') format('woff'),
    url('/fonts/alrightsans-blackitalic-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-blackitalic-webfont.svg#alright_sansblack_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alright_sansbold';
    src: url('/fonts/alrightsans-bold-webfont.eot');
    src: url('/fonts/alrightsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-bold-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-bold-webfont.woff') format('woff'),
    url('/fonts/alrightsans-bold-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-bold-webfont.svg#alright_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alright_sansbold_italic';
    src: url('/fonts/alrightsans-bolditalic-webfont.eot');
    src: url('/fonts/alrightsans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-bolditalic-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-bolditalic-webfont.woff') format('woff'),
    url('/fonts/alrightsans-bolditalic-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-bolditalic-webfont.svg#alright_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'alright_sansmedium';
    src: url('/fonts/alrightsans-medium-webfont.eot');
    src: url('/fonts/alrightsans-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-medium-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-medium-webfont.woff') format('woff'),
    url('/fonts/alrightsans-medium-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-medium-webfont.svg#alright_sansmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'alright_sansmedium_italic';
    src: url('/fonts/alrightsans-mediumitalic-webfont.eot');
    src: url('/fonts/alrightsans-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-mediumitalic-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-mediumitalic-webfont.woff') format('woff'),
    url('/fonts/alrightsans-mediumitalic-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-mediumitalic-webfont.svg#alright_sansmedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'alright_sansregular';
    src: url('/fonts/alrightsans-regular-webfont.eot');
    src: url('/fonts/alrightsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-regular-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-regular-webfont.woff') format('woff'),
    url('/fonts/alrightsans-regular-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-regular-webfont.svg#alright_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alright_sansregular_italic';
    src: url('/fonts/alrightsans-regularitalic-webfont.eot');
    src: url('/fonts/alrightsans-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-regularitalic-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-regularitalic-webfont.woff') format('woff'),
    url('/fonts/alrightsans-regularitalic-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-regularitalic-webfont.svg#alright_sansregular_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alright_sansthin';
    src: url('/fonts/alrightsans-thin-webfont.eot');
    src: url('/fonts/alrightsans-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-thin-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-thin-webfont.woff') format('woff'),
    url('/fonts/alrightsans-thin-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-thin-webfont.svg#alright_sansthin') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alright_sansthin_italic';
    src: url('/fonts/alrightsans-thinitalic-webfont.eot');
    src: url('/fonts/alrightsans-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/alrightsans-thinitalic-webfont.woff2') format('woff2'),
    url('/fonts/alrightsans-thinitalic-webfont.woff') format('woff'),
    url('/fonts/alrightsans-thinitalic-webfont.ttf') format('truetype'),
    url('/fonts/alrightsans-thinitalic-webfont.svg#alright_sansthin_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

$font-regular: 'alright_sansregular', Arial, Helvetica, sans-serif;
$font-medium: 'alright_sansmedium', Arial, Helvetica, sans-serif;
$font-bold: 'alright_sansbold', Arial, Helvetica, sans-serif;
$font-black: 'alright_sansblack', Arial, Helvetica, sans-serif;
