.page-component-modal-mask,
.page-component-modal {
    display: none;
    position: fixed;

    &.open {
        display: block;
    }
}

.page-component-modal-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .65);
    z-index: 9998;
}

.page-component-modal {
    $width: 80%;

    position: fixed;
    top: 10%;
    left: ((100% - $width) / 2);
    width: $width;
    height: 80%;
    background: #fff;
    border: 1px solid #aaa;
    z-index: 9999;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 20px 18px;
    overflow-y: auto;

    .close-button {
        position: absolute;
        top: 14px;
        right: 15px;
        color: #919ba1;
    }

    h2 {
        font-size: 1.5em;
        margin: 0 0 16px;
    }

    .page-component-modal-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .page-component-modal-form-content {
        overflow-y: auto;
        padding-right: 1em;
        height: 100%;
    }

    .button-row {
        margin: 1em 0 0;

        button {
            &:not(:last-child) {
                margin-right: 0.3em;
            }
        }
    }
}

@media (min-width: 1420px) {
    .page-component-modal {
        $width: 62%;
        $height: 82%;

        top: ((100% - $height) / 2);
        left: ((100% - $width) / 2);
        width: $width;
        height: $height;
    }
}
