$ember-power-select-border-color: #ccd0d4;
$ember-power-select-line-height: 2.7;
$ember-power-select-placeholder-color: #555;
$ember-power-select-trigger-icon-color: #555;

@import 'ember-power-select';

@import 'fonts';
@import 'bootstrap';
@import 'color_admin';

.filter-toolbar {
    label {
        margin-right: 5px;
    }
}

.sidebar-menu {
    overflow: hidden;
    width: auto;
    height: 100%;
}

.nav {
    &.nav-tabs {
        background-color: $bg-color;

        a {
            cursor: pointer;
        }
    }
}

.dataTables_wrapper {
    margin-bottom: 7px;
}

.ember-text-area {
    width: 100%;
    min-height: 120px;
}

.file-upload {
    max-width: 200px;
    max-height: 200px;
}

.gritter-item {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    padding: 3px 15px 4px !important;

    .gritter-title {
        font-size: 1.4em !important;
        padding-bottom: 6px !important;
    }

    p {
        font-size: 1.2em;
    }

    strong {
        color: #00ACAC;
    }
}

.movie-tab-content {
    min-height: calc(100vh - 245px);

    .tab-pane {
        position: relative;
    }
}

#asset-upload-progressbar-container {
    float: left;
    margin-left: 12px;
}

#asset-upload-progress-bar {
    width: 200px;
    background-color: #ddd;
}

#asset-upload-progress-bar-value {
    background-color: #674ea0;
}

#asset-upload-progress-bar,
#asset-upload-progress-bar-value {
    border-radius: 3px;
    height: 33px;
}

.movie-assets-table {
    .movie-asset-preview-image {
        width: 175px;
        cursor: zoom-in;
    }

    .asset-type-description {
        display: block;
        font-size: .9me;
        color: #aaa;
        margin-top: 2px;
    }
}

.helper-text {
    color: #aaa;
    font-size: .9em;
}

$manage-published-state-popup-open-button-width: 85px;

.manage-published-state-popup-open-button {
    width: $manage-published-state-popup-open-button-width;
}

.manage-published-state-popup-open-dropdown-button {
    a {
        &.dropdown-toggle {
            width: $manage-published-state-popup-open-button-width;
        }
    }
}

.manage-published-state-button {
    display: inline-block;
}

.manage-published-state-popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1050;
}

$manage-published-state-popup-width: 320px;

.manage-published-state-popup {
    $height: 205px;

    position: fixed;
    top: 50%;
    left: 50%;
    width: $manage-published-state-popup-width;
    height: $height;
    margin-top: -($height / 2);
    margin-left: -($manage-published-state-popup-width / 2);
    background-color: #fff;
    z-index: 1051;

    $padding: 12px;
    padding: $padding ($padding + 2px);

    $box-shadow-blur: 16px;
    $box-shadow-opacity: .6;
    box-shadow: 0 0 $box-shadow-blur rgba(0, 0, 0, $box-shadow-opacity);

    $border-radius: 2px;
    border-radius: $border-radius;

    p {
        font-size: .9em;
    }
}

.manage-published-state-popup-title {
    display: block;
    font-weight: bold;
    margin-bottom: 7px;
}

.manage-published-state-popup-close-button {
    $color: #777;
    $margin: 7px;

    position: absolute;
    top: $margin;
    right: $margin;
    text-decoration: none;
    color: $color;
    border: 0;
    background-color: #fff;

    &:hover {
        color: lighten($color, 15%);
    }

    &:focus {
        outline: none;
    }
}

.manage-published-state-popup-buttons {
    $margin: 14px;

    position: absolute;
    left: $margin;
    bottom: $margin;
    width: $manage-published-state-popup-width - ($margin * 2);
}

.manage-published-state-popup-future-container,
.manage-published-state-popup-unpublish-container {
    .form-control {
        height: 29px;
    }
}

.form-control {
    max-width: 100%;
    border-radius: 2px;
}

.manage-published-state-popup-future-container {
    margin-top: 7px;
}

.manage-published-state-popup-unpublish-container {
    .checkbox {
        margin-bottom: 5px;
    }
}

.inline-block {
    display: inline-block;
}

.table {
    td .description {
        display: block;
        color: #aaa;
        font-size: .8em;
    }

    .sub-info {
        display: block;
    }
}

#documentation-pages-list {
    padding-left: 0;
    list-style-position: inside;
}

.role-table-heading {
    &:not(:first-of-type) {
        margin-top: 12px;
    }

    h5 {
        float: left;
        margin-right: 10px;
    }
}

.seo-image {
    height: 100px;
    margin-bottom: 10px;
}

.ember-view {
    .is-loading {
        display: none;
    }
}

.seo-type-row {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.seo-type-box {
    background-color: #fff;
    border-radius: 3px;
    padding: 12px 14px 16px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .12);

    .heading,
    .helper-text {
        float: left;
    }

    .heading {
        display: block;
        font-size: 1.2em;
        font-weight: bold;
        margin: 0 0 8px;
    }

    .helper-text {
        margin: 3px 0 0 4px;
    }
}

.loader-box-container {
    margin-top: 12px;

    &.with-text.centered {
        &.medium {
            .loader-box {
                $width: 100px;
                $height: 30px;

                width: $width;
                height: $height;
                margin-top: -($height / 2);
                margin-left: -($width / 2);
            }
        }
    }

    &.centered {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .loader-box {
            $sidebar-width: 220px;
            $header-height: 54px;

            position: absolute;
            top: calc(50% + #{($header-height / 2)});
            left: calc(50% + #{($sidebar-width / 2)});

            @media screen and (max-width: 768px) {
                $header-height: 109px;

                top: calc(50% + #{($header-height / 2)});
                left: 50%;
            }
        }

        &.medium {
            .loader-box {
                $width: 30px;
                $height: 30px;

                width: $width;
                height: $height;
                margin-top: -($height / 2);
                margin-left: -($width / 2);
            }
        }
    }

    &.no-top-margin {
        margin-top: 0;
    }

    i,
    span {
        float: left;
    }

    span {
        margin: 3px 0 0 6px;
        font-size: 1.1em;
    }
}

.models-table-wrapper {
    margin: 15px 0;

    .form-inline.globalSearch {
        margin-bottom: 15px;
    }

    .changePageSize {
        visibility: hidden;
    }

    .table-header {
        &[data-ember-action] {
            cursor: pointer;
        }
    }

    .table-footer {
        margin-top: 1em;

        .table-nav {
            text-align: right;

            a {
                display: inline-block;
                margin-top: 2px;
            }
        }
    }

    tbody tr:hover {
        td {
            background-color: #f5f5f5;
        }
    }
}

$service-resource-header-height: 42px;

.service-resource-list {
    $horizontal-cell-padding: 12px;
    $border-color: #bbb;

    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $border-color;
    margin-bottom: 20px;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .5);

        .loader-box {
            $width: 60px;
            $height: 60px;

            position: absolute;
            left: 50%;
            top: 50%;
            width: $width;
            height: $height;
            margin-top: -($height / 2) + ($service-resource-header-height / 2);
            margin-left: -($width / 2);
            text-align: center;

            i {
                display: block;
                margin: 0 auto 4px;
            }
        }
    }

    .service-resource-header {
        display: table-row;
    }

    .header-column,
    .field {
        display: table-cell;
    }

    .header-column {
        font-weight: bold;
        background-color: #242a30;
        color: #fff;
        padding: 12px $horizontal-cell-padding;

        // &.position {
        //     width: 15%;
        // }
    }

    .service-resource-items {
        display: table-row-group;
    }

    .service-resource {
        display: table-row;
        cursor: move;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &.is-dragging-object {
            opacity: .8;
        }

        &:hover .field,
        &:nth-child(odd) .field {
            background-color: #f5f5f5;
        }

        .field {
            background-color: #fff;
            padding: 7px $horizontal-cell-padding;
            border-bottom: 1px solid $border-color;
        }

        &:nth-child(odd) .field {
            background-color: #f0f3f5;
        }
    }
}

@media screen and (max-width: 922px) {
    #people-filter-toolbar {
        .form-group {
            &:first-child {
                margin-bottom: 10px;
            }
        }
    }
}

.alert-table-load-error {
    margin-top: 12px;
}

.form-group.required label::after {
    content: ' *';
    color: #ff0000;
}

.thumbnail-preview-image-container {
    margin-bottom: 10px;

    img {
        height: 100px;
    }
}

.tab-pane {
    .table {
        margin-bottom: 0;
    }
}

.ql-editor {
    min-height: 260px;

    p {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 0.4em;
    }
}

.ql-container,
.ql-toolbar {
    background-color: #fff;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.label {
    cursor: default;

    &.label-live {
        background-color: #5cb85c;
    }
}

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.globalSearch {
    .filterString {
        width: 300px;
    }
}

.drag-handle {
    cursor: move;
    cursor: grab;
}

.form-control,
.pxl-form-select {
    border-top: 0;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .3), 0 -1px 1px #FFF, 0 1px 0 #FFF;
    // background-color: #f9f9f9;

    &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .3), 0 -1px 1px #FFF, 0 1px 0 #FFF;
        border-bottom: 1px solid #DDD;
    }
}

.lightboxOverlay {
    z-index: 99998;
}

.lightbox {
    z-index: 99999;
}

.preview-media-image {
    display: block;
    width: auto;
    height: 80px;
    margin-bottom: 1em;
}

.users-table {
    &__flags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    &__flag {
        display: inline-block;
        max-height: 18px;
        position: relative;
        top: 1px;
    }
}


@import 'mobile';
@import 'universal-brand';
@import 'pxl-media-library';

@import 'components/pxl-asset-preview';
@import 'components/pxl-form-gallery';
@import 'components/pxl-form-items';
@import 'components/pxl-form-select';
@import 'components/pxl-form-listbox';
@import 'components/pxl-form-field';
@import 'components/pxl-form-file';
@import 'components/pxl-youtube-video-form-field';
@import 'components/thumbnail-preview-image';
@import 'components/retailer-editor';
@import 'components/select-language-container';

@import 'dreamworks';
@import 'uwatch/faqs';
