.media-theater-item-modal-mask,
.media-theater-item-modal {
    display: none;
    position: fixed;

    &.open {
        display: block;
    }
}

.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.media-theater-item-modal-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .25);
    z-index: 9998;
}

.media-theater-item-modal {
    $width: 800px;
    $height: 620px;

    top: 50%;
    left: 50%;
    width: $width;
    height: $height;
    margin-top: -($height / 2);
    margin-left: -($width / 2);
    background: #fff;
    border: 1px solid #aaa;
    z-index: 9999;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 18px;

    .close-button {
        position: absolute;
        top: 14px;
        right: 17px;
    }

    h2 {
        font-size: 1.5em;
        margin: 0 0 12px;
    }

    $height-without-header: calc(100% - 27px);

    form {
        height: $height-without-header;
    }

    .resources-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // height: $height-without-header;
    }

    .resource-container {
        p {
            margin-bottom: 0;
        }

        .pxl-form-select {
            top: -10px;
            width: 400px;
        }
    }

    .button-row {
        display: flex;
        justify-content: flex-start;
        position: absolute;
        bottom: 15px;

        button {
            &:not(:last-of-type) {
                margin-right: 4px;
            }
        }
    }

    .media-theater-types {
        display: flex;
        justify-content: space-around;
        margin-bottom: 0.5em;

        label {
            cursor: pointer;

            &:not(:last-of-type) {
                margin-right: 1em;
            }
        }
    }

    // CUSTOM OPTION STYLES
    .media-theater-options {
        label {
            margin-right: 0.5em;
        }
    }

    .custom-text-fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .pxl-form-field-group {
            width: 48%;
        }
    }

    .pxl-form-field-group {
        width: 48%;
    }

    .custom-image-fields {
        display: flex;

        .pxl-form-file {
            width: 50%;
        }
    }

    .custom-cta-buttons {
        .cta-buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    label {
        font-size: larger;
       // position:absolute;
        text-align:center;
        // bottom:0;
    
    }
}
