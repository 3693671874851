.select-language-container {
    display: flex;
    align-items: center;

    &__flag {
        display: block;
        max-height: 33px;
        position: relative;
        top: 6px;
    }

    &__label {
        margin-right: 0.75rem;
    }
}
