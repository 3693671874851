.resource-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &:first-child {
        display: flex;
        align-items: center;
    }

    .badge {
        $bg-color: #ffaa00;
        $text-color: #fff;

        color: $text-color;
        background: $bg-color;
        border-color: $bg-color;
    }

    &.live {
        .badge {
            $bg-color: #56b928;
            $text-color: #fff;

            color: $text-color;
            background: $bg-color;
            border-color: $bg-color;
        }
    }

    .resource-toolbar-column {
        display: flex;
        align-items: center;

        // &.resource-toolbar-column-buttons {
        // }

        &.resource-toolbar-column-status {
            .status-text {
                margin: 0 10px 0 5px;
            }

            .btn {
                &:not(:last-child) {
                    margin-right: 6px;
                }
            }
        }
    }

    .unsaved-changes-text {
        margin-left: 5px;
        font-style: italic;
        color: #aaa;
    }
}
