.resource-retailer-editor {
    .season-toolbar,
    .add-retailer-toolbar {
        display: flex;
        align-items: center;
    }

    .season-toolbar-actions,
    .add-retailer-button {
        margin-left: 0.5em;
    }

    .retailer {
        display: flex;
        align-items: center;
    }

    .retailers-list {
        .row {
            &:not(:last-of-type) {
                margin-bottom: 1em;
            }
        }

        .form-control--alt-text {
            margin-left: 0.5rem;
        }
    }

    .delete-trailer-button {
        margin-left: 1em;
    }

    .add-retailer-container {
        margin-top: 1.5em;
    }

    .retailer-name {
        width: 175px;
    }

    .drag-handle {
        margin-right: 1em;
    }
}
