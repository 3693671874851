.universal-brand-page-layout-manager {
    .load-error-alert {
        margin-bottom: 0;
    }

    .selected-version-content {
        margin-top: 20px;
    }

    .version-dropdown-column {
        @media screen and (max-width: 991px) {
            margin-bottom: 10px;
        }
    }

    .version-dropdown {
        $height: 34px;
        $horizontal-padding: 10px;

        position: relative;
        width: 100%;
        height: $height;
        border: 1px solid #c1ccd1;
        border-radius: 3px;
        cursor: pointer;

        &.open {
            .items {
                display: block;
            }
        }

        .selected-item {
            .selected-version-text {
                position: absolute;
                top: 8px;
                left: $horizontal-padding;

                &.no-version {
                    color: #ccc;
                }
            }

            .fa {
                position: absolute;
                top: 11px;
                right: $horizontal-padding;
            }
        }

        .items {
            $border-radius: 3px;

            display: none;
            position: absolute;
            top: $height - 1px;
            left: -1px;
            border: 1px solid #c1ccd1;
            width: calc(100% + 2px);
            z-index: 9999;
            background: #fff;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            .item {
                $background-color: #fff;
                $vertical-padding: 10px;

                padding: $vertical-padding 9px;
                font-size: .9em;
                background-color: $background-color;

                &:hover {
                    background-color: darken($background-color, 2%);
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #dedede;
                }

                &.heading {
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    cursor: default;
                    padding-top: $vertical-padding + 1px;
                    color: #bbb;
                    letter-spacing: 0.04em;

                    &:hover {
                        background-color: $background-color;
                    }
                }

                &.current-version {
                    font-weight: bold;

                    &:hover {
                        background-color: $background-color;
                    }
                }

                &.current-version,
                &.disabled {
                    color: #ccc;
                    cursor: default;
                }

                .version-title {
                    float: left;
                }

                .version-status {
                    float: right;
                }
            }

            .divider {
                width: 95%;
                height: 1px;
                background-color: #ddd;
                margin: 0 auto;
            }
        }
    }

    .live-date-column {
        .schedule-button {
            float: left;
        }

        .live-status-text {
            float: left;
            margin: 3px 0 0 .6em;
        }
    }
}

table .edit-button {
    float: right;
    color: #348fe2;
    cursor: pointer;
}

.gallery-image-preview,
.table-image {
    max-height: 100px;
}

.tab-pane {
    .alert-danger {
        margin-bottom: 0;
    }
}

.social-icon {
    color: #348fe2;
}

// Footer Menu Editor
.footer-menu-editor {
    width: 100%;
    margin-top: 1em;

    .footer-menu-items {
        $width: 32%;

        &.left {
            float: left;
            width: $width;
            margin-right: 1em;
        }

        &.center {
            float: left;
            width: $width;
            margin-right: 1em;
        }

        &.right {
            float: left;
            width: $width;
        }

        .items-title {
            display: block;
            text-transform: uppercase;
            margin-bottom: 3px;
            font-weight: bold;
        }

        $border-color: #888;

        .items {
            border: 1px solid $border-color;
            background-color: #fff;
            min-height: 200px;
        }

        .footer-menu-item {
            $border: 1px solid $border-color;

            position: relative;
            padding: 13px 10px 12px;
            width: 100%;
            background-color: #e3e3e3;
            border-bottom: $border;

            .drag-handle,
            .title {
                float: left;
            }

            .drag-handle {
                margin-right: 1em;
            }

            .actions {
                position: absolute;
                top: 13px;
                right: 10px;

                a {
                    color: #666;
                }
            }
        }
    }
}

.hide {
    display: none;
}

.archived {
    opacity: 0.2;
}

.worlds-category-field {
    margin-bottom: 1em;
}
