.leaders {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    padding: 1em;

    .leader {
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 1em;
        }

        .drag-handle {
            margin-right: 0.8em;
        }
    }

    .leader,
    .leader-data {
        display: flex;
        align-items: center;
    }

    .item-actions {
        display: flex;
        align-items: center;

        .btn {
            &:not(:last-child) {
                margin-right: 0.4em;
            }
        }
    }

    .published-state {
        margin-right: 0.5em;
    }

    .badge {
        margin-right: 0.5em;
        padding: 1px 5px;
    }

    .badge-live {
        background: #56b928;
    }

    .leader-image {
        display: block;
        width: auto;
        height: 80px;
        margin-right: 0.5em;
    }

    .saving-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 9;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
