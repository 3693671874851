.retailer-editor {
    .add-retailer-toolbar {
        display: flex;
        align-items: center;
    }

    .add-retailer-button {
        margin-left: 0.5em;
    }

    .retailer {
        display: flex;
        align-items: center;
    }

    .retailers-list {
        .row {
            &:not(:last-of-type) {
                margin-bottom: 1em;
            }
        }
    }

    .delete-retailer-button {
        margin-left: 1em;
    }

    .retailer-name {
        width: 175px;
    }

    .featured {
        line-height: 24px;
        margin-bottom: 0;
        margin-left: 20px;
        margin-right: 20px;
    }

    .drag-handle {
        margin-right: 1em;
    }
}
