.featured-carousel {
    .resource-selector-form {
        width: 100%;
        margin-right: 1em;
    }

    .add-resource-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 0.5em;
        height: 52px;

        .add-resource-button {
            position: relative;
            top: 22px;
        }
    }
}
