.resource-offer-editor {
    .add-retailer-toolbar {
        display: flex;
        align-items: center;
    }

    .add-retailer-button {
        margin-left: 0.5em;
    }

    .retailer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .retailer-name {
            font-size: 18px;
            font-weight: 700;
            margin-right: 20px;
            color: #000;
            text-transform: uppercase;
        }
    }
}
