.page-header-container {
    display: flex;
    align-items: center;

    &.with-margin-bottom {
        margin-bottom: 1em;
    }

    h1 {
        margin-right: 0.7em;
    }

    .back-button {
        position: relative;
        top: -3px;
    }

    &__flag {
        display: block;
        max-height: 21px;
        position: relative;
        top: -3px;
        left: 8px;
    }
}

.resource-editor-content {
    &.hide {
        display: none;
    }
}

.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: larger;
}
