.dreamworks-posts-editor {
    h2 {
        margin-top: 0;
        font-size: 1.6em;
    }

    .post {
        position: relative;
        display: flex;
        justify-content: space-between;
        background-color: #f9f9f9;
        border-radius: 6px;
        padding: 1.2em;
        min-height: 75px;

        &:not(:last-child) {
            margin-bottom: 1.2em;
        }

        &.disabled {
            opacity: 0.5;
        }

        .post-content {
            display: flex;
            align-items: center;
        }

        .post-image {
            margin-right: 1em;
            cursor: zoom-in;

            img {
                height: 80px;
            }
        }

        .post-date {
            position: absolute;
            bottom: 1em;
            right: 1em;
        }

        .actions {
            min-width: 100px;
        }
    }
}
