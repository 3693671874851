.dreamworks-clear-cache-form {
    .horizontal-radio-buttons-form-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .radio {
            margin: 0;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            label {
                line-height: 22px;
            }
        }
    }
}
