.pxl-form-select-container {
    position: relative;
    min-height: 48px;
    margin-bottom: 20px;
}

.pxl-form-select {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(204, 208, 212);
    background-color: #fff;
    padding: 5px 11px 7px;
    border-radius: 2px;
    z-index: 9;

    &.top {
        z-index: 10;
    }

    &.loading {
        opacity: 0.5;
    }

    .selected-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        top: 2px;
        position: relative;
    }

    .selector {
        display: block;
        width: 16px;
        height: 16px;
        top: 3px;
        right: -3px;
    }

    .options {
        display: none;

        &.show {
            display: block;
            max-height: 235px;
            overflow-y: auto;
            margin-top: 0.5em;
        }

        .option {
            padding: 4px 6px;
            cursor: pointer;

            &:not(:last-of-type) {
                border-bottom: 1px solid #ddd;
            }

            &:hover {
                background-color: #f7f7f7;
            }

            &.selected {
                background-color: #f7f7f7;
                cursor: default;
                font-weight: bold;
            }
        }
    }

    .search-input {
        width: 100%;
        margin-right: 8px;
        padding: 4px 6px;
        margin-bottom: 4px;

        &:focus {
            outline: none;
        }
    }
}
