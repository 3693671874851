.resource-list {
    &.loading {
        .resource-list-loader {
            display: block;
            margin: 0.2em auto 0;
        }
    }

    .resource-types {
        label {
            &:not(:last-child) {
                margin-right: 1em;
            }

            input {
                &[type='radio'] {
                    margin: 4px 4px 0 0;
                }
            }
        }
    }

    .add-resource-container {
        display: flex;
        align-items: flex-start;
        height: 60px;

        .add-resource-button {
            position: relative;
            top: 21px;
        }
    }

    .resources {
        margin-top: 1em;

        .resource {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5em 1em;

            &:not(:last-of-type) {
                margin-bottom: 0.7em;
            }

            &:nth-child(odd) {
                background-color: #f9f9f9;
            }

            .remove-resource-button {
                color: #666;
            }

            .drag-handle {
                margin-right: 1em;
            }

            .resource-content {
                display: flex;
                align-items: center;
            }

            .resource-data {
                display: flex;
                align-items: center;

                img {
                    margin-right: 0.6em;
                }
            }
        }
    }
}
