.pxl-form-file {
    &.has-required-dimensions-text {
        label {
            margin-bottom: 2px;
        }
    }

    .required-dimensions-text {
        display: block;
        color: #aaa;
        font-size: 0.9em;
        margin: 0 0 0.5em;
    }

    .loader {
        display: block;
        margin-top: 0.1em;
    }
}
