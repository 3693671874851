.media-theater-editor {
    .add-item-button {
        margin-bottom: 12px;
    }

    #layout-items {
        display: flex;
        flex-direction: column;

        .item {
            display: flex;
            flex-direction: row;
            flex-basis: calc(50% - 20px);
            align-items: center;
            justify-content: space-between;
            border: 1px solid #bbb;
            border-radius: 3px;
            background-color: #f9f9f9;
            min-height: 50px;
            padding: 12px;

            .item-content {
                display: flex;
                align-items: center;
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            .drag-handle {
                margin-right: 8px;
            }

            .item-type {
                font-size: 0.8em;
                color: #ccc;
            }
        }
    }

    .no-items-text {
        margin-bottom: 0;
    }

    .fa-refresh {
        display: block;
        margin: 0 auto;
    }
}
