.resource-selector-form {
    width: 100%;
    margin-right: 1em;

    &.compact {
        .resource-selector-form-options {
            justify-content: space-around;
        }
    }

    .resource-selector-form-options {
        display: none;
        justify-content: space-between;
        margin-bottom: 1em;

        &.show {
            display: flex !important;
        }

        .resource-selector-form-option {
            display: flex;
        }
    }

    .resource-selector-form-resource {
        width: 100%;
        display: flex;
        border: 1px solid #ccc;
        background-color: #f2f2f2;
        padding: 12px;

        .resource-title-container {
            display: flex;
            align-items: center;
        }
    }

    .resource-selector-form-loading {
        min-height: 46px;
    }

    .resource-selector-form-resource-container {
        .pxl-form-select {
            width: 100%;
            top: -15px;
        }
    }
}
