.dreamworks-business-partners-editor {
    .add-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 1em;

        input {
            &[type='text'] {
                margin-right: 1em;
            }
        }

        .btn {
            max-width: 50px;
        }
    }

    .partners {
        border: 1px solid #bbb;
        background-color: #f9f9f9;
        padding: 12px;
        display: flex;
        flex-direction: column;
        border-radius: 3px;

        .partner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &:not(:last-of-type) {
                margin-bottom: 1em;
            }
        }

        .partner-data {
            display: flex;

            .drag-handle {
                margin-right: 1em;
            }
        }

        .partner-note {
            font-size: 0.9em;
            color: #bbb;
        }

        .item-actions {
            a {
                color: #707478;
            }
        }
    }
}
