.pxl-youtube-video-form-field-input-group {
    .input-group-addon {
        font-size: 1em;
    }

    .input-group-btn {
        button {
            font-size: 12px;
            padding: 8px 12px 7px;
        }
    }
}
