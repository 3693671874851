.pxl-media-library {
    position: fixed;
    width: 80%;
    max-width: 960px;
    max-height: calc(100% - 100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    display: none;

    &.open {
        display: block;
    }

    .close-button {
        position: absolute;
        top: 21px;
        right: 24px;
        color: #6e7179;
        z-index: 1;
    }

    .search-query-input {
        position: absolute;
        top: 15px;
        width: 250px;
        right: 70px;
    }

    .modal-body {
        padding: 10px;
        min-height: 650px;
    }

    table {
        width: 100%;

        th,
        td {
            vertical-align: top;
            padding: 8px;
        }

        tbody {
            tr:nth-child(odd) {
                background-color: #f2f2f2;
            }
        }
    }

    .table-wrapper {
        position: relative;
        width: 100%;
        overflow-y: auto;
        height: 478px;
    }

    .tab-content,
    .pagination {
        margin-bottom: 0;
    }

    .media-item-name {
        display: block;
    }

    .pagination {
        .item {
            a {
                cursor: pointer !important;
            }
        }
    }

    .required-dimensions {
        position: absolute;
        top: 22px;
        right: 350px;
        display: flex;

        .required-dimensions-column {
            &:first-of-type {
                margin-right: 1em;
            }
        }
    }
}

.pxl-media-library-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.media-gallery-photo {
    cursor: zoom-in;

    img {
        max-height: 40px;
    }
}

.media-gallery-preview-image {
    display: inline-block;
    cursor: zoom-in;

    img {
        display: block;
        max-height: 100px;
        margin-bottom: 8px;
    }
}

.pxl-media-library-upload-preview-image {
    display: block;
    max-height: 100px;
    margin-bottom: 0.5em;
}

.required-dimensions-warning {
    color: #f00;

    &.ignored {
        color: #ddd;
    }
}

.no-items {
    text-align: center;
    width: 100%;
    display: block;
}


dt,
dd {
    display: inline;
}

dd {
    margin-right: 1em;
}

#pxl-media-library-tab-gallery {
    &.loading {
        height: 530px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
