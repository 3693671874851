.navigation-manager {
    width: 100%;

    .navigation-item-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &__prefix-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            width: 75px;

            .item-index {
                font-weight: bold;
                margin-right: 1rem;
            }

            .btn {
                &:first-child {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    .sub-item-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .sub-item-prefix-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            width: 75px;
            text-align: center;

            .sub-item-index {
                font-weight: bold;
                margin-right: 1rem;
            }

            .btn {
                &:first-child {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    h3 {
        margin: 0 0 0.5rem;
        font-size: 1.6rem;
    }

    .navigation-table,
    .sub-items-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 10px;
        th, td {
            border: 1px solid #ccc;
            padding: 10px;
            text-align: left;
        }
        th {
            background-color: #f9f9f9;
        }
    }

    .navigation-table {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    .navigation-item {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 10px;

        h3 {
            font-size: 1.4rem;
            margin: 1.25rem 0 0.35rem;
        }

        .no-sub-items-text {
            margin: 0;
        }
    }
}
