.page-components-manager {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    .components-column {
        display: flex;
        flex-direction: column;
        width: 45%;

        .heading-text {
            margin: 0 0 12px;
            font-size: 1.3em;
        }
    }

    .components-container {
        position: relative;
        border: 1px solid #aaa;
        border-radius: 2px;
        background-color: #f9f9f9;
        padding: 12px;
        height: 100%;

        &.empty {
            .empty-container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }

            .components {
                display: flex;
                align-items: center;
                justify-content: center;

                &::before {
                    content: 'No components';
                    color: #ccc;
                }
            }

            .no-components-text {
                color: #ccc;
            }
        }
    }

    .components {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .component {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border: 1px solid #aaa;
            border-radius: 2px;
            background-color: #fff;
            height: 40px;
            cursor: grab;

            box-shadow: inset 0.1em 0.1em 0.2em 0 #f2f2f2, inset -1px -1px 0.2em 0 rgba(0, 0, 0, 0.15);

            &:not(:last-of-type) {
                margin-bottom: 8px;
            }
        }
    }

    .component-info {
        display: flex;
        align-items: center;
    }

    .component-name {
        font-size: .8em;
        color: #afafaf;
        margin-left: 5px;
    }

    .lock-icon {
        margin: 0 0.5em 0 0.1em;
    }

    .pxl-asset-preview {
        display: inline-block;
        margin-right: 7px;
    }
}
